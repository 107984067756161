import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import appConfig from './appConfig'

// import {AuthModule, FetchAppPermissions } from '@carrier/usermanagement-authmodule';
import { ReactAuthWrapper } from '@carrier/reactauthwrapper';

ReactDOM.render(
  <ReactAuthWrapper appConfig={appConfig.api} >
    <App />
  </ReactAuthWrapper>
  ,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
