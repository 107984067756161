const tenant = 'carriertools.onmicrosoft.com';

export const appConfig = {
    api: {
        // apiUrl: 'https://api.carrierappshub.com/',
        // postLogoutRedirectUri: 'http://localhost:3000',
        // uiUrl: 'https://prod.residentialratingtool.com',
        // redirectAppUri: "https://dev2509.d1wpvfhtnqsn73.amplifyapp.com",
        // userPoolId: "us-east-2_0ozfAABUx",\

        uiUrl: "https://prod.residentialratingtool.com",
        apiUrl: "https://api.carrierappshub.com/",
        postLogoutRedirectUri: "http://localhost:3000"
    }
}
export default appConfig;



