import React, { useEffect } from "react";
import "typeface-roboto";
import "../App.css";
import { Router, navigate } from "@reach/router";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Container,
  Card,
  CardContent,
  Toolbar,
  IconButton,
  Dialog,
  CircularProgress,
  DialogContent,
  Icon,
  Avatar,
  AppBar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../assets/logo.png";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Store } from "../store/Actions";
import { useStore } from "../store/Store";
import { GlobalStore } from "../store/Actions";
import axios from "axios";
import { logout } from '@carrier/reactauthwrapper';
import appConfig from '../appConfig'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "100%",
  },
  media: {
    paddingTop: "0%", // 16:9
  },
  avatar: {
    backgroundColor: "transparent",
    width: "100px",
  },
  toolbar: {
    minHeight: 90,
  },

  top: {
    marginTop: "50px",
  },
  grow: {
    flexGrow: 1,
  },
  appbar: {
    background: "#0060b9",
  },
  title: {
    flexGrow: 1,
  },
}));

const options = ["Clean-Workspaces"];

function Header(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menu_open = Boolean(anchorEl);

  const { currentSurrogate, setSmData } = useStore(GlobalStore);
  const StoreActions = Store();
  const [open, setOpen] = React.useState(false);

  const {
    username
  } = props;

  useEffect(() => {
    return function () { };
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem('user');
    logout(appConfig.api);
    // console.log("flag -----------------------2")
  }

  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar variant="dense" className={classes.toolbar}>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="Open drawer"
        >
          <Avatar
            aria-label="Logo"
            src={logo}
            className={classes.avatar}
          ></Avatar>
          {/* <img alt="Carrier" src="https://files.carrier.com/carrier/en/contentimages/carrier-logo-92x56.png"/> */}
        </IconButton>
        <Typography className={classes.title} variant="h6" noWrap>
          RES Rating Tool
          <Typography className={classes.title} variant="body2" noWrap>
            One Stop For Data
          </Typography>

        </Typography>
        <Typography edge="end" onClick={(evt) => navigate("/")}>
          Welcome {username}
        </Typography>
        <IconButton edge="end" onClick={handleLogout}>
          <Icon style={{ color: "#ffffff" }}>logout</Icon>
        </IconButton>
      </Toolbar>
    </AppBar>

  );
}

export default Header;
