import React from "react";
import { useStore } from "./Store";

export const initialValue = {
  indoorUnit: [],
  outdoorUnit: [],
  furnace: [],
}


export const GlobalStore = () => {

  // console.log('Global Store called');

  const [currentSurrogate, setCurrentSurrogate] = React.useState(initialValue);

  const setworkSpace = (workSpace) => { console.log(currentSurrogate, "set workspace"); setCurrentSurrogate({ ...initialValue, computeplatform: currentSurrogate.computeplatform, workSpace: workSpace, workflowType: currentSurrogate.workflowType }) }

  const setSmData = (data) => { console.log(currentSurrogate, "sm data", data); setCurrentSurrogate({ ...currentSurrogate, smData: { ...data} }) }

  const setClonedworkSpace = (workSpace) => { console.log(currentSurrogate, "set cloned workspace"); setCurrentSurrogate({ ...currentSurrogate, workSpace: workSpace }) }
  const setSelectedPlatform = (platform) => { console.log(currentSurrogate, "set platform"); setCurrentSurrogate({ ...currentSurrogate, computeplatform: { ...platform } }) }
  const setSelectedWorkflowType = (workSpaceType) => { console.log(currentSurrogate, "set setSelectedWorkflowType"); setCurrentSurrogate({ ...initialValue, computeplatform: currentSurrogate.computeplatform, workSpace: currentSurrogate.workSpace, workflowType: workSpaceType }) }

  // const setGeometry = (geometry) => { console.log(currentSurrogate); setCurrentSurrogate({ ...currentSurrogate, sampling: {}, geometry: geometry }) }

  // const setStoreSampling = (sampling) => { console.log(currentSurrogate); setCurrentSurrogate({ ...currentSurrogate, sampling: sampling }) }

  // const setComputePlatform = (computeplatform) => { console.log(currentSurrogate); setCurrentSurrogate({ ...currentSurrogate, computeplatform: computeplatform }) }

  // const setStoreCompiler = (compiler) => {
  //   // console.log(currentSurrogate);
  //   setCurrentSurrogate({ ...currentSurrogate, compiler: compiler });
  // }


  // const setSolver = (solver) => {
  //   // console.log(currentSurrogate);
  //   setCurrentSurrogate({ ...currentSurrogate, solver: solver });
  // }
  // const log = () => console.log(currentSurrogate)


  // return { currentSurrogate, setCurrentSurrogate,setworkSpace, setGeometry, log, setStoreSampling, setStoreCompiler, setSolver, setComputePlatform,setClonedworkSpace };
  return { currentSurrogate, setCurrentSurrogate, setworkSpace, setClonedworkSpace,setSelectedPlatform, setSmData, setSelectedWorkflowType };
};


export const Store = () => useStore(GlobalStore);




// export const actions = {
//   setjobName: (state, jobName) => ({ ...state, jobName: jobName }),
//   setworkSpace: (state, workSpace) => ({ ...state, workSpace: workSpace }),
//   setSystem: (state, system) => ({ ...state, system: system }),
//   setComponent: (state, component) => ({ ...state, component: component }),
//   setGeometry: (state, geometry) => ({ ...state, geometry: geometry }),
//   setSampling: (state, sampling) => ({ ...state, sampling: sampling }),
//   setCompiler: (state, compiler) => ({ ...state, compiler: compiler }),
//   log: (state) => console.log(state)
// }
