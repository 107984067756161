import React, { useEffect, useState } from "react";
import "typeface-roboto";
import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Chip from '@material-ui/core/Chip';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Dialog,
  CircularProgress,
  DialogContent,
  Snackbar,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles } from "@material-ui/core/styles";
import { Provider } from "./store/Store";
import { GlobalStore } from "./store/Actions";
import Header from "./pages/Header";
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { DropzoneArea, DropzoneAreaBase } from 'material-ui-dropzone';
import axios from "axios";
import { saveAs } from 'file-saver';


const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "100%",
  },
  media: {
    paddingTop: "0%", // 16:9
  },
  avatar: {
    backgroundColor: "transparent",
    width: "100px",
  },
  top: {
    marginTop: "10px",
  },
  grow: {
    flexGrow: 1,
  },
  btn: {
    color: "black"
  },
  appbar: {
    background: "#0060b9",
  },
  title: {
    flexGrow: 1,
  },
  uploadBox: {
    float: "left",
    margin: '15px',
    width: "100%"
  },
  infobox: {
    float: "left",
    margin: '15px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#0060b9',
  },
  uploadpanel: {
    height: "250px"
  },
  downloadsPanel: {
    width: '40%',
  },
  rootgrid: {
    width: '90%',
    right: 0,
    left: 0,
    margin: 'auto'
  },
  noPadding: {
    padding: "0 !important",
    borderRadius: '7px',
    border: '2px solid #a3c8edc9',
    marginRight: "50px"

  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
  selectformControl: {
    margin: theme.spacing(1),
    width: 280
  },
  dropzonearea: {
    minHeight: "185px"
  },

  space: {
    width: "4px",
    height: "auto",
    display: "inline-block"
  }


}));

const options = ["Clean-Workspaces"];

const App = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);

  const [outdoorUnit, setoutdoorUnit] = useState([]);
  const [indoorUnit, setindoorUnit] = useState([]);
  const [furnace, setfurnace] = useState([]);
  const [fileObjects, setfileObjects] = useState([]);

  const [username, setusername] = useState('');

  const [resetFiles, setresetFiles] = useState(true);
  const [uploadstatus, setuploadstatus] = useState('');
  const [timerID, settimerid] = useState('');
  const [openComment, setcommnet] = useState(false)
  const [userComment, setuserCommnet] = useState('')
  const [uploaddata, setuploaddata] = useState(new FormData())
  var date = new Date();
  const [startDate, setstartDate] = useState(new Date(date.getFullYear() - 1, date.getMonth(), date.getDate()));
  const [endDate, setendDate] = useState(new Date());
  // let timerID = null;
  useEffect(() => {
    if (uploadstatus == "success") {
      setOpen(true);
      setResponse({
        Loading: false,
        Message: "Files uploaded Successfully !!!",
        isSuccess: true,
        isAlert: false,
      });
      setresetFiles(false);
      setfileObjects([])
      clearInterval(timerID)
      console.log("Files uploaded Successfully !!!")
    } else if (uploadstatus == "failed") {
      setOpen(true);
      setResponse({
        Loading: false,
        Message: "Failed to upload files. Please contact admin ..!!!",
        isSuccess: false,
        isAlert: true,
      });
      clearInterval(timerID)
    }
    setuploadstatus("")
    // console.log("we found success or failed..!!----", timerID)
  }, [uploadstatus])

  const [selectedOutdoor, setselectedOutdoor] = useState([]);
  const [selectedIndoor, setselectedIndoor] = useState([]);
  const [selectedFurnace, setselectedFurnace] = useState([]);

  const [selectedCalTable, setselectedCalTable] = useState(false);
  const [selectedRunResultsTable, setselectedRunResultsTable] = useState(false);
  const [selectedRatingResultsTable, setselectedRatingResultsTable] = useState(false);
  const [selectedAHRIACResultsTable, setselectedAHRIACResultsTable] = useState(false);
  const [selectedAHRIHPResultsTable, setselectedAHRIHPResultsTable] = useState(false);
  const [selectedExpandedRatingsTable, setselectedExpandedRatingsTable] = useState(false);

  const [auditOutdoor, setauditOutdoor] = useState([]);

  const [auditCalTable, setauditCalTable] = useState(false);
  const [auditExpandedRatings, setauditExpandedRatings] = useState(false);
  const [auditRatingResultsTable, setauditRatingResultsTable] =
    useState(false);
  const [auditAHRIACResultsTable, setauditAHRIACResultsTable] =
    useState(false);
  const [auditAHRIHPResultsTable, setauditAHRIHPResultsTable] =
    useState(false);
  const [auditTables, setauditTables] = useState([]);
  const [response, setResponse] = useState({
    isSuccess: false,
    Message: null,
    Note: null,
    Loading: true,
  });

  //  const fetchAPI = axios.create({baseURL: "https://residentialratingtool.com/api" } );
  const fetchAPI = axios.create({ baseURL: "https://pt6om61t50.execute-api.us-east-1.amazonaws.com/dev/api" });

  // const fetchAPI = axios.create({ baseURL: "http://localhost:8000/api" });


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (newFiles) => {
    newFiles = newFiles.filter(file => !fileObjects.find(f => f.data === file.data));
    setfileObjects([...fileObjects, ...newFiles]);
  }

  const handleDelete = (deleted) => {
    setfileObjects(fileObjects.filter((f) => f !== deleted));
  };

  const handleFilter = (event) => {
    postFilter({})
  }

  const handleAudit = (event) => {
    postAudit({});
  };

  const handleFilter_to_download = (event) => {
    postFilter_download({})
  }

  const download_ahri_cond_char = (event) => {
    download_ahri_cond({})
  }

  const handleSubmit = (event) => {
    postFinish({ files: fileObjects })
  }

  const handleCommnetOK = async () => {

    setcommnet(false)
    let uplaodpayload = uploaddata
    const blob = new Blob([userComment], { type: 'text/plain' })
    uplaodpayload.append("comment.txt", blob, "comment.txt")

    for (var value of uplaodpayload.values()) {
      console.log(value)
    }

    setOpen(true)
    setResponse({
      Loading: true,
      Message: "Please wait, Files are Uploading !!!",
      isSuccess: false,
      isAlert: false,
    });

    fetchAPI.post("/upload_files", uplaodpayload, {
      headers: {
        "Content-type": "multipart/form-data",
        "Authorization": `Bearer ${localStorage.getItem('msal.idtoken')}`
      }
    })
      .then(res => {
        console.log("files uploaded successfully....................................")
        console.log(res.data.file_name);
        setresetFiles(false);
        setfileObjects([]);
        let tempID = setInterval(getuploadstatus, 10000, res.data.file_name);
        settimerid(tempID)
        console.log("stauts from main-----");
        setOpen(false)
      });
    setuserCommnet('')
  }

  const handleCommnetCancel = () => {
    setcommnet(false)
    setOpen(false)
    setuserCommnet('')
  }

  let accepted_files = ['AHRI Results_AC', 'AHRI Results_HP', 'Calc_Table', 'Ratings_Results', 'Run_Results', 'AHRI_COND', '.zip']
  // const data = new FormData();

  const postFinish = () => {
    if (fileObjects.length > 0) {
      // let accepted_files = ['AHRI Results_AC.csv', 'AHRI Results_HP.csv', 'Calc_Table.csv', 'Ratings_Results.csv', 'Run_Results.csv', 'upload_files.zip']
      let accepted_files = ['AHRI Results_AC',
        'AHRI Results_HP', 'Calc_Table',
        'Ratings_Results', 'Run_Results',
        '.zip', 'AHRI_COND',
        '_Carrier.csv', '_ICP.csv',
      ]
      let missing_files = []

      const data = new FormData();
      for (var i = 0; i < fileObjects.length; i++) {
        let file_name = fileObjects[i].file.name
        let ismatching = false
        for (var j = 0; j < accepted_files.length; j++) {
          if (file_name.toLowerCase().indexOf(accepted_files[j].toLowerCase()) != -1) {
            console.log(accepted_files[j])
            ismatching = true
          }
        }
        if (!ismatching) {
          missing_files.push(fileObjects[i].file.name)
        }
      }

      if (missing_files.length > 0) {
        setOpen(true);

        setResponse({
          Loading: false,
          Message: "Invalid filenames: " + missing_files.join(', ') + '.',
          Note: 'Valid file names starts should with :' + accepted_files.join(', '),
          isSuccess: false,
          isAlert: true,
        });
        return ''
      }

      for (var i = 0; i < fileObjects.length; i++) {
        data.append(fileObjects[i].file.name, fileObjects[i].file);
      }
      setuploaddata(data)
      setcommnet(true)

      // setResponse({
      //   Loading: true,
      //   Message: "Please wait, Files are Uploading !!!",
      //   isSuccess: false,
      //   isAlert: false,
      // });

      // let filter_check_result = await axios.get("https://residentialratingtool.com/api/check")
      // let filter_check_result = await fetchAPI.get("/check", {
      //   headers: {
      //     "Content-Type": "application/json;charset=UTF-8",
      //     "Authorization": `Bearer ${localStorage.getItem('msal.idtoken')}`
      //   }
      // });

      // let user_input = '';

      // if (filter_check_result.data.length > 0) {
      //   user_input = window.confirm("We already have data in DB. Do you want update existing records");
      // } else {
      //   user_input = true;
      // }
      // if (user_input) {
      //   setOpen(true);
      //   fetchAPI.post("/upload_files", data, {
      //     headers: {
      //       "Content-type": "multipart/form-data",
      //       "Authorization": `Bearer ${localStorage.getItem('msal.idtoken')}`
      //     }
      //   })
      //     .then(res => {

      //       console.log("files uploaded successfully....................................")
      //       console.log(res.data.file_name);
      //       setresetFiles(false);
      //       setfileObjects([]);
      //       let tempID = setInterval(getuploadstatus, 10000, res.data.file_name);
      //       settimerid(tempID)
      //       console.log("stauts from main-----");
      //       setOpen(false)
      //     });
      // } else {
      //   setResponse({
      //     Loading: false,
      //     Message: "Files upload is unsuccessful !!!",
      //     isSuccess: false,
      //     isAlert: true,
      //   });
      // }
    } else {
      setResponse({
        Loading: false,
        Message: "Please uploade a file(s) !!!",
        isSuccess: false,
        isAlert: true,
      });

    }
  }

  const getuploadstatus = (event) => {
    // let status = "";
    let filename = {};
    console.log('event', event);
    filename["filename"] = event;
    fetchAPI.post("/upload_status", filename, {
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "Authorization": `Bearer ${localStorage.getItem('msal.idtoken')}`
      }
    }).then(res => {
      setuploadstatus(res.data.status);
    });
  }

  const updatedefaultvalues = (event) => {
    setOpen(false);
    setresetFiles(false);
    setfileObjects([])

    setselectedOutdoor([])
    setselectedIndoor([])
    setselectedFurnace([])
    setselectedCalTable(false)
    setselectedRunResultsTable(false)
    setselectedRatingResultsTable(false)
    setselectedAHRIACResultsTable(false)
    setselectedAHRIHPResultsTable(false)
    setselectedExpandedRatingsTable(false)
    setauditAHRIACResultsTable(false)
    setauditAHRIHPResultsTable(false)
    setauditCalTable(false)
    setauditExpandedRatings(false)
    setauditOutdoor([])
    setauditRatingResultsTable(false)
    setauditTables([])
  }
  const handledefaultvalues = (event) => {
    setOpen(false);
  }


  const postAudit = async () => {
    // debugger;
    let data = {};

    data['Outdoor'] = auditOutdoor.length > 0 ? auditOutdoor.map((v) => v.title) : ""
    data['coll_names'] = auditTables
    data['from_date'] = startDate
    data['to_date'] = endDate

    setOpen(true);

    setResponse({
      Loading: true,
      Message: "Please wait, we are processing !!!",
      isSuccess: false,
      isAlert: false,
    });

    if (
      auditTables.length > 0
    ) {
      try {
        //console.log("only RUN RESULTS is selected..!!")
        await fetchAPI
          .post("/audit",
            data
            , {
              timeout: 1000 * 32,
              responseType: "arraybuffer",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
              },
            })
          .then((res) => {
            console.log(res, res.export_file_error, res.data);
            try {
              var decodedString = String.fromCharCode.apply(
                null,
                new Uint8Array(res.data)
              );
              var obj = JSON.parse(decodedString);
            } catch (err) {
              var obj = {};
            }
            console.log(obj);
            if (obj.export_file_error) {
              setResponse({
                Loading: false,
                Message: "Error while downloading Audit data !!!",
                isSuccess: true,
                isAlert: false,
              });
            } else if (obj.no_data_error) {
              setResponse({
                Loading: false,
                Message: "Audit data is not available in database !!!",
                isSuccess: true,
                isAlert: false,
              });
            }
            else {
              const blob = new Blob([res.data], {
                type: "application/octet-stream",
              });
              const filename = "Audit.zip";
              saveAs(blob, filename);

              setResponse({
                Loading: false,
                Message: "Files Downloaded Successfully !!!",
                isSuccess: true,
                isAlert: false,
              });
            }
          })
          .catch((error) => {
            let message = "Error";
            let message_body = JSON.parse(
              String.fromCharCode.apply(
                null,
                new Uint8Array(error.response.data)
              )
            )?.message;
            if (message_body) {
              message = message_body;
            }
            setResponse({
              Loading: false,
              Message: message,
              isSuccess: false,
              isAlert: true,
            });
          });
      } catch (err) {
        console.log(
          "err?.response?.data?.message",
          err?.response?.data?.message
        );
        setResponse({
          Loading: false,
          Message: "Error",
          isSuccess: false,
          isAlert: true,
        });
      }
    } else {
      setResponse({
        Loading: false,
        Message: "Please select any of the table",
        isSuccess: false,
        isAlert: true,
      });
    }
  };


  const postFilter = async (data1) => {
    let data = {}
    setOpen(true);

    data['Indoor'] = selectedIndoor.length > 0 ? selectedIndoor.map(v => (v.title)) : [];
    data['Outdoor'] = selectedOutdoor.length > 0 ? selectedOutdoor.map(v => (v.title)) : [];
    data['Furnace'] = selectedFurnace.length > 0 ? selectedFurnace.map(v => (v.title)) : [];
    data['Cal_Table'] = selectedCalTable ? true : false
    data['Run_Results'] = selectedRunResultsTable ? true : false
    data['Rating_Results'] = selectedRatingResultsTable ? true : false
    data['AHRI_Results_AC'] = selectedAHRIACResultsTable ? true : false
    data['AHRI_Results_HP'] = selectedAHRIHPResultsTable ? true : false
    data['Expanded_OD'] = selectedExpandedRatingsTable ? true : false

    const axiosOptions = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    setResponse({
      Loading: true,
      Message: "Please wait, we are processing !!!",
      isSuccess: false,
      isAlert: false,
    });

    if (selectedRunResultsTable || selectedAHRIACResultsTable || selectedAHRIHPResultsTable || selectedCalTable || selectedRatingResultsTable || selectedExpandedRatingsTable) {
      try {
        //console.log("only RUN RESULTS is selected..!!")
        await fetchAPI.post("/export", data, {
          timeout: 1000 * 32,
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${localStorage.getItem('msal.idtoken')}`
          }
        }).then((res) => {
          setResponse({
            Loading: false,
            Message: "Files will be sent via Email!!!",
            isSuccess: true,
            isAlert: false,
          });
        }).catch((error) => {
          let message = "Error"
          let message_body = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)))?.message;
          if (message_body) {
            message = message_body
          }
          setResponse({
            Loading: false,
            Message: message,
            isSuccess: false,
            isAlert: true,
          });
        })
      } catch (err) {
        console.log("err?.response?.data?.message", err?.response?.data?.message);
        setResponse({
          Loading: false,
          Message: "Error",
          isSuccess: false,
          isAlert: true,
        });
      }
    }
    else {
      setResponse({
        Loading: false,
        Message: "Please select any of the table",
        isSuccess: false,
        isAlert: true,
      });

    }
  }

  const download_ahri_cond = async (data1) => {
    let data = {}
    setOpen(true);

    data['AHRI_COND_CHAR'] = true

    const axiosOptions = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    setResponse({
      Loading: true,
      Message: "Please wait, Files are Downloading !!!",
      isSuccess: false,
      isAlert: false,
    });

    try {
      fetchAPI.post("/download_cond_char", data, {
        timeout: 1000 * 32,
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem('msal.idtoken')}`
        }
      })
        .then(res => {
          console.log(res, res.export_file_error, res.data)
          try {
            var decodedString = String.fromCharCode.apply(null, new Uint8Array(res.data));
            var obj = JSON.parse(decodedString);
          } catch (err) {
            var obj = {}
          }
          console.log(obj)
          if (obj.export_file_error) {
            setResponse({
              Loading: false,
              Message: "Data is not available in database !!!",
              isSuccess: true,
              isAlert: false,
            });

          }
          else {
            const blob = new Blob([res.data], {
              type: 'application/octet-stream'
            })
            const filename = 'AHRI_COND_CHAR.zip'
            saveAs(blob, filename)

            setResponse({
              Loading: false,
              Message: "Files Downloaded Successfully !!!",
              isSuccess: true,
              isAlert: false,
            });
          }
        })
    } catch (err) {
      console.log(err);
      setResponse({
        Loading: false,
        Message: "Error while exporting AHRI_COND_CHAR..!",
        isSuccess: false,
        isAlert: true,
      });
    }
  }

  const postFilter_download = async (data1) => {
    let data = {}
    setOpen(true);

    data['Indoor'] = selectedIndoor.length > 0 ? selectedIndoor.map(v => (v.title)) : [];
    data['Outdoor'] = selectedOutdoor.length > 0 ? selectedOutdoor.map(v => (v.title)) : [];
    data['Furnace'] = selectedFurnace.length > 0 ? selectedFurnace.map(v => (v.title)) : [];
    data['Cal_Table'] = selectedCalTable ? true : false
    data['Run_Results'] = selectedRunResultsTable ? true : false
    data['Rating_Results'] = selectedRatingResultsTable ? true : false
    data['AHRI_Results_AC'] = selectedAHRIACResultsTable ? true : false
    data['AHRI_Results_HP'] = selectedAHRIHPResultsTable ? true : false

    const axiosOptions = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    setResponse({
      Loading: true,
      Message: "Please wait, Files are Downloading !!!",
      isSuccess: false,
      isAlert: false,
    });


    if (selectedRunResultsTable || selectedExpandedRatingsTable) {
      setResponse({
        Loading: false,
        Message: "Use Export option to get data !!!",
        isSuccess: false,
        isAlert: true,
      });
    }
    else if (selectedAHRIACResultsTable || selectedAHRIHPResultsTable || selectedCalTable || selectedRatingResultsTable) {
      try {
        fetchAPI.post("/download", data, {
          timeout: 1000 * 32,
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${localStorage.getItem('msal.idtoken')}`
          }
        })
          .then(res => {
            console.log(res, res.export_file_error, res.data)
            try {
              var decodedString = String.fromCharCode.apply(null, new Uint8Array(res.data));
              var obj = JSON.parse(decodedString);
            } catch (err) {
              var obj = {}
            }
            console.log(obj)
            if (obj.export_file_error) {
              setResponse({
                Loading: false,
                Message: "Data is not available in database !!!",
                isSuccess: true,
                isAlert: false,
              });

            }
            else {
              const blob = new Blob([res.data], {
                type: 'application/octet-stream'
              })
              const filename = 'Export.zip'
              saveAs(blob, filename)


              setResponse({
                Loading: false,
                Message: "Files Downloaded Successfully !!!",
                isSuccess: true,
                isAlert: false,
              });

            }

          })
        //          .catch(error => {
        //            console.log(error)
        //            setResponse({
        //              Loading: false,
        //              Message: "Unable to download large data..!! Please contact admin for manual download..!!",
        //              isSuccess: false,
        //              isAlert: true,
        //            });
        //          });
      } catch (err) {
        console.log(err);
        setResponse({
          Loading: false,
          Message: "Error",
          isSuccess: false,
          isAlert: true,
        });
      }
    }
    else {
      setResponse({
        Loading: false,
        Message: "Please select any of the table",
        isSuccess: false,
        isAlert: true,
      });

    }
  }

  const fetchData = async () => {
    setOpen(true);
    setResponse({
      Loading: true,
      Message: "",
      isSuccess: false,
      isAlert: false,
    });

    const filter_input_result = await fetchAPI.get("/getinfo", {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Authorization": `Bearer ${localStorage.getItem('msal.idtoken')}`
      }
    });


    //    const filter_input_result = await axios("https://residentialratingtool.com/api/getinfo");
    // filter_input_result = JSON.parse(filter_input_result.data)
    if (filter_input_result.data) {
      const indoorOptions = []
      for (var key in filter_input_result.data.indoor.sort()) {
        indoorOptions.push({ 'title': filter_input_result.data.indoor[key] })
      }

      const outdoorOptions = []
      for (var key in filter_input_result.data.outdoor.sort()) {
        outdoorOptions.push({ 'title': filter_input_result.data.outdoor[key] })
      }

      const furnaceOptions = []
      for (var key in filter_input_result.data.furnace.sort()) {
        furnaceOptions.push({ 'title': filter_input_result.data.furnace[key] })
      }
      setindoorUnit(indoorOptions)
      setoutdoorUnit(outdoorOptions)
      setfurnace(furnaceOptions)
      setfileObjects([])
      setusername(filter_input_result.data.username)
      setOpen(false);
    } else {
      console.log("An Error Occurred in api !!!")
      setResponse({
        Loading: false,
        Message: "",
        isSuccess: false,
        isAlert: false,
      });
    }
  };

  useEffect(() => {
    fetchData();
    setfileObjects([])

  }, [resetFiles]);
  return (
    <Provider stores={[GlobalStore]}>
      <React.Fragment>
        <CssBaseline />
        <Container
          component="main"
          fixed
          maxWidth="lg"
          className={classes.media}
        >
          <Card className={classes.card}>
            <Header position="static" className={classes.appbar} username={username}></Header>
            <CardContent>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <label id={"lbl"} htmlFor={"rdo"}>
                    <Typography variant="h6" className={classes.btn}>
                      Upload CSV File(s)
                    </Typography>
                  </label>
                </AccordionSummary>
                <AccordionDetails>

                  <Grid container className={classes.rootgrid} >
                    <Grid item xs={12} >

                      <div className={"panel " + classes.uploadpanel}>
                        <div className={'information-box ' + classes.uploadBox}>
                          <label id={'lbl'} htmlFor={'rdo'}>
                            <Typography variant="h6" className={classes.btn} >Upload CSV File(s)</Typography>
                          </label>
                          <DropzoneAreaBase
                            acceptedFiles={['.csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'zip', 'application/zip', 'application/x-zip', 'application/x-zip-compressed']}
                            showFileNames={true}
                            showPreviewsInDropzone={true}
                            fileObjects={fileObjects}
                            dropzoneClass={classes.dropzonearea}
                            useChipsForPreview={true}
                            maxFileSize={5242880}
                            filesLimit={10}
                            clearOnUnmount={true}
                            onAdd={handleChange}
                            onDelete={handleDelete}
                          />
                          <p style={{ color: 'red', font: "small-caption" }}>* File names should start with: {accepted_files.join(', ')}</p>
                          <p style={{ color: 'red', font: "small-caption" }}>* Expanded Ratings Files also allowd which ends with '_Carrier.csv' and '_ICP.csv'</p>
                          <Button
                            type="button"
                            style={{ top: '50%', left: '45%' }}
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleSubmit}
                          >
                            Upload
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <div style={{ marginTop: "10px" }}></div>
              <div>
                <Grid >
                  <React.Fragment>

                    <Grid >
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <label id={"lbl"} htmlFor={"rdo"}>
                            <Typography variant="h6" className={classes.btn}>
                              Download Results
                            </Typography>
                          </label>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className={'information-box ' + classes.infobox}>
                            <FormControl variant="outlined" className={classes.selectformControl}>
                              <Autocomplete
                                className={classes.inputControl}
                                multiple
                                disableCloseOnSelect
                                id="outdoorunit"
                                options={outdoorUnit}
                                value={selectedOutdoor}
                                getOptionLabel={(option) => option.title}
                                // filterSelectedOptions
                                onChange={(event, newValue) => {
                                  setselectedOutdoor(newValue);
                                }}
                                renderTags={(value, getTagProps) => (
                                  <Chip
                                    variant="outlined"
                                    label={
                                      value.length === 1 ? `${value[0].title}` : `${value.length} selected`
                                    }
                                    size="small"
                                    className={classes.selectedOptionsChip}
                                    title={
                                      value.length === 1
                                        ? `${value[0].title}`
                                        : `${value.map(item => item.title).join(', ')}`
                                    }
                                  />
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="OutDoor Unit"
                                    placeholder="OutDoor Unit"
                                    fullWidth
                                  />
                                )}
                              />

                            </FormControl>
                            <FormControl variant="outlined" className={classes.selectformControl}>
                              <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="indoorunit"
                                value={selectedIndoor}
                                options={indoorUnit}
                                getOptionLabel={(option) => option.title}
                                onChange={(event, newValue) => {
                                  setselectedIndoor(newValue);
                                }}
                                renderTags={(value, getTagProps) => (
                                  <Chip
                                    variant="outlined"
                                    label={
                                      value.length === 1 ? `${value[0].title}` : `${value.length} selected`
                                    }
                                    size="small"
                                    className={classes.selectedOptionsChip}
                                    title={
                                      value.length === 1
                                        ? `${value[0].title}`
                                        : `${value.map(item => item.title).join(', ')}`
                                    }
                                  />
                                )}

                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="InDoor Unit"
                                    placeholder="InDoor Unit"
                                  />
                                )}
                              />

                            </FormControl>
                            <FormControl variant="outlined" className={classes.selectformControl}>
                              <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="furnace"
                                value={selectedFurnace}
                                options={furnace}
                                getOptionLabel={(option) => option.title}
                                onChange={(event, newValue) => {
                                  setselectedFurnace(newValue);
                                }}
                                renderTags={(value, getTagProps) => (
                                  <Chip
                                    variant="outlined"
                                    label={
                                      value.length === 1 ? `${value[0].title}` : `${value.length} selected`
                                    }
                                    size="small"
                                    className={classes.selectedOptionsChip}
                                    title={
                                      value.length === 1
                                        ? `${value[0].title}`
                                        : `${value.map(item => item.title).join(', ')}`
                                    }
                                  />
                                )}

                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Furnace"
                                    placeholder="Furnace"
                                  />
                                )}
                              />

                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    value={selectedCalTable}
                                    checked={selectedCalTable == true}
                                    onChange={(event, newValue) => {
                                      setselectedCalTable(newValue);
                                    }}

                                  />
                                }
                                label="Cal Table"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    value={selectedRatingResultsTable}
                                    checked={selectedRatingResultsTable == true}

                                    onChange={(event, newValue) => {
                                      setselectedRatingResultsTable(newValue);
                                    }}

                                  />
                                }
                                label="Rating Results Table"
                              />
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    value={selectedRunResultsTable}
                                    checked={selectedRunResultsTable == true}

                                    onChange={(event, newValue) => {
                                      setselectedRunResultsTable(newValue);
                                    }}

                                  />
                                }
                                label="Run Results Table"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    value={selectedAHRIACResultsTable}
                                    checked={selectedAHRIACResultsTable == true}

                                    onChange={(event, newValue) => {
                                      setselectedAHRIACResultsTable(newValue);
                                    }}

                                  />
                                }
                                label="AHRI Results AC Table"
                              />
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    value={selectedAHRIHPResultsTable}
                                    checked={selectedAHRIHPResultsTable == true}

                                    onChange={(event, newValue) => {
                                      setselectedAHRIHPResultsTable(newValue);
                                    }}

                                  />
                                }
                                label="AHRI Results HP Table"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="checkedB"
                                    color="primary"
                                    value={selectedExpandedRatingsTable}
                                    checked={selectedExpandedRatingsTable == true}

                                    onChange={(event, newValue) => {
                                      setselectedExpandedRatingsTable(newValue);
                                    }}

                                  />
                                }
                                label="Expanded Ratings"
                              />
                            </FormControl>
                            <div align="Center">
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={handleFilter}
                              >
                                Export
                              </Button>
                              <div className={classes.space}>
                              </div>
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={handleFilter_to_download}
                              >
                                Download
                              </Button>
                              <div className={classes.space}>
                              </div>
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={download_ahri_cond_char}
                              >
                                Download_AHRI_COND
                              </Button>
                            </div>

                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Dialog disableEscapeKeyDown={false} open={open}>
                      <DialogContent>
                        {response.Loading && <CircularProgress></CircularProgress>}
                        <Typography variant="h6" color="primary" style={{ whiteSpace: "pre-wrap" }}>
                          {response.Message}
                        </Typography>
                        <Typography style={{ color: 'red', font: "small-caption" }}>
                          {response.Note}
                        </Typography>
                      </DialogContent>
                      {response.isSuccess && !response.isAlert && (
                        <DialogActions>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={updatedefaultvalues}
                          >
                            Ok
                          </Button>
                        </DialogActions>
                      )}
                      {response.isAlert && (
                        <DialogActions>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handledefaultvalues}

                          >
                            Ok
                          </Button>
                        </DialogActions>
                      )}
                    </Dialog>
                    <Dialog disableEscapeKeyDown={false} open={openComment}>
                      <DialogTitle>
                        <Typography variant="h6" color="primary" style={{ whiteSpace: "pre-wrap" }}>
                          Comments
                        </Typography>
                      </DialogTitle>
                      <DialogContent>
                        <Typography variant="h6" color="primary" style={{ whiteSpace: "pre-wrap" }}>
                          <TextField
                            onChange={(e) => setuserCommnet(e.target.value)}
                            autoFocus
                            fullWidth
                            placeholder='Your comments'
                          />
                        </Typography>

                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={userComment.length < 1}
                          onClick={handleCommnetOK}>
                          OK
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleCommnetCancel}>
                          Cancel
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                </Grid>
              </div>
              <div style={{ marginTop: "10px" }}></div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6" className={classes.btn}>
                    Audit History
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container className={classes.rootgrid}>
                    <Grid item xs={12}>

                      <FormControl
                        variant="outlined"
                        className={classes.selectformControl}
                      >
                        <Autocomplete
                          className={classes.inputControl}
                          multiple
                          disableCloseOnSelect
                          id="outdoorunit"
                          options={outdoorUnit}
                          value={auditOutdoor}
                          getOptionLabel={(option) => option.title}
                          // filterSelectedOptions
                          onChange={(event, newValue) => {
                            setauditOutdoor(newValue);
                          }}
                          renderTags={(value, getTagProps) => (
                            <Chip
                              variant="outlined"
                              label={
                                value.length === 1
                                  ? `${value[0].title}`
                                  : `${value.length} selected`
                              }
                              size="small"
                              className={classes.selectedOptionsChip}
                              title={
                                value.length === 1
                                  ? `${value[0].title}`
                                  : `${value
                                    .map((item) => item.title)
                                    .join(", ")}`
                              }
                            />
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="OutDoor Unit"
                              placeholder="OutDoor Unit"
                              fullWidth
                            />
                          )}
                        />

                      </FormControl>
                      <FormControl
                        variant="outlined"
                        className={classes.selectformControl}
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newValue) => {
                              setstartDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        className={classes.selectformControl}
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newValue) => {
                              setendDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checkedB"
                              color="primary"
                              value={auditCalTable}
                              checked={auditCalTable == true}
                              onChange={(event, newValue) => {
                                setauditCalTable(newValue);
                                if (newValue == true) {
                                  setauditTables(auditTables => [...auditTables, 'Cal_Table'])
                                  console.log(auditTables)
                                }
                                else {
                                  let index = auditTables.indexOf('Cal_Table')
                                  setauditTables([
                                    ...auditTables.slice(0, index),
                                    ...auditTables.slice(index + 1, auditTables.length)
                                  ]);
                                  console.log(auditTables)
                                }
                              }}
                            />
                          }
                          label="Cal Table"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checkedB"
                              color="primary"
                              value={auditRatingResultsTable}
                              checked={auditRatingResultsTable == true}
                              onChange={(event, newValue) => {
                                setauditRatingResultsTable(newValue);
                                if (newValue == true) {
                                  setauditTables(auditTables => [...auditTables, 'Rating_Results'])
                                }
                                else {
                                  let index = auditTables.indexOf('Rating_Results')
                                  setauditTables([
                                    ...auditTables.slice(0, index),
                                    ...auditTables.slice(index + 1, auditTables.length)
                                  ]);
                                }
                              }}
                            />
                          }
                          label="Rating Results Table"
                        />
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >

                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checkedB"
                              color="primary"
                              value={auditAHRIACResultsTable}
                              checked={auditAHRIACResultsTable == true}
                              onChange={(event, newValue) => {
                                setauditAHRIACResultsTable(newValue);
                                if (newValue == true) {
                                  setauditTables(auditTables => [...auditTables, 'AHRI_Results_AC'])
                                }
                                else {
                                  let index = auditTables.indexOf('AHRI_Results_AC')
                                  setauditTables([
                                    ...auditTables.slice(0, index),
                                    ...auditTables.slice(index + 1, auditTables.length)
                                  ]);

                                }
                              }}
                            />
                          }
                          label="AHRI Results AC Table"
                        />
                        {/* <FormControlLabel
																control={
																	<Checkbox
																		name="checkedB"
																		color="primary"
																		value={auditExpandedRatings}
																		checked={auditExpandedRatings == true}
																		onChange={(event, newValue) => {
																			setauditExpandedRatings(newValue);
																			if (newValue == true)
																			{
																				setauditTables(auditTables => [...auditTables, 'Expanded Ratings'])
																			}
																			else
																			{
																				let index = auditTables.indexOf('Expanded Ratings')
																				setauditTables([
																					...auditTables.slice(0, index),
																					...auditTables.slice(index + 1, auditTables.length)
																				  ]);

																			}
																		}}
																	/>
																}
																label="Expanded Ratings"
															/> */}
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checkedB"
                              color="primary"
                              value={auditAHRIHPResultsTable}
                              checked={auditAHRIHPResultsTable == true}
                              onChange={(event, newValue) => {
                                setauditAHRIHPResultsTable(newValue);
                                if (newValue == true) {
                                  setauditTables(auditTables => [...auditTables, 'AHRI_Results_HP'])
                                }
                                else {
                                  let index = auditTables.indexOf('AHRI_Results_HP')
                                  setauditTables([
                                    ...auditTables.slice(0, index),
                                    ...auditTables.slice(index + 1, auditTables.length)
                                  ]);

                                }
                              }}
                            />
                          }
                          label="AHRI Results HP Table"
                        />


                      </FormControl>
                      <div align="Center">
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                          onClick={handleAudit}
                        >
                          Audit
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Container>
      </React.Fragment>
    </Provider >
  );
};

export default App;
